<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Ventancat
        </h2>
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Recover"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Recuperación de la contraseña.
          </b-card-title>
          <b-card-text class="mb-2">Se ha enviado un correo con los datos de acceso. Revisa tu bandeja de entrada.</b-card-text>
          <b-card-text class="mb-2">
            <p class="text-center mt-2">
            <span>Puedes acceder </span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;aquí</span>
            </b-link>
          </p>
          </b-card-text>
          <!-- form -->
          
        </b-col>
      </b-col>
    <!-- /Recover-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
  export default {
    components: {
      VuexyLogo,
      BRow,
      BImg,
      BCol,
      BLink,
      BButton,
      BForm,
      BCardText,
      BCardTitle,
      BFormCheckbox,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        status: '',
        userEmail: '',
        sideImg: require('@/assets/images/pages/register-v2.svg'),
        // validation
        required,
        email,
      }
    },
    methods: {
      
    },          
  }
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
